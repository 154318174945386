<template>
  <div role="main" class="main">
    <section class="common-page with-footer speaker-room-page" style="" id=image v-if="isFullScreen==false">
      <div class="container-fluid">
        <div class="row" style="width: 100%; margin-left: 0px">
          <div class="col-md-3">
            <div style="padding: 0 50px">
              <div class="profile-pic" v-if="current_speaker != null && !isCloseInfo">
                <img
                  :src="current_speaker.profile_url"
                  class="mr-2 mb-1 live_profile_img"
                />
                {{ current_speaker.speaker_name }}
              </div>
              <h6
                style="max-height: calc(100vh - 59vh); overflow-y: auto"
                class="text-primary text-left mt-3"
                v-if="current_speaker != null && !isCloseInfo"
              >
                {{ current_speaker.bio }}
              </h6>
            </div>
          </div>

          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-9">
            <div class="live-video" style="padding: 0 0">
              <div
                class="frame-wrapper c-frame-wrapper"
                style="width: 100%; position: relative"
              >
              <div
                  class="frame-wrapper frame-outline"
                >
                  <img
                    :src="require('@/assets/img/frame.png')"
                    style="
                      position: absolute;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    "
                  />
                  <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>

                  <!-- <iframe
                   
                    src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e"
                    allow="autoplay; encrypted-media"
                    class="frame_wrapper"
                  ></iframe> -->
                </div>
              </div>
              <!-- <div class="frame-wrapper" style="width: 100%">
                <img
                  :src="require('@/assets/img/frame.png')"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 59vh;
                    z-index: 1;
                  "
                />
              </div> -->

              <div class="text-center css-layout" v-if="show_css_layout">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style="height: 100%"
                >
                  <h2 style="color: yellow">
                    Stand by<br />for our<br />next speaker.<br />Please click
                    Join Service<br />
                    once you see an image
                  </h2>
                </div>
              </div>
              <transition name="pop" appear>
                <div
                  class="modal fade show custom-modal"
                  v-if="show_count_modal"
                  id="Countdown"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Countdown"
                  aria-hidden="true"
                  style="z-index: 9000"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    style="width: 300px !important"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <h5 class="text-custom my-4">
                              Stand by<br />for our<br />next speaker.
                            </h5>
                            <div class="countdown">
                              {{ count_number }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <button
              id="join_service"
              class="btn-play"
              v-show="runningVideo"
              onclick="document.getElementById('remote_video').play();document.getElementById('join_service').remove()"
            >
              Join Service
            </button>
            <div class="position-absolute" style="right:-3px;bottom:-17px;z-index:999">
              <v-btn
              rounded
              class="play-btn"
              text
              @click="changeScreenView()"
              >
                <img
                style="height:40px;background-color: black;"
                :src="maximize"
                />
              </v-btn>
            </div>
          </div>
          <!-- <div class="col-md-3"></div> -->
        </div>
      </div>
    </section>
    <section class="common-page with-footer speaker-room-page" style="" v-if="isFullScreen==true">
      <div class="container-fluid">
        <div class="row" style="width: 100%; margin-left: 0px">
          <div class="col-md-3">
            <div style="padding: 0 50px">
              <div class="profile-pic" v-if="current_speaker != null">
                <img
                  :src="current_speaker.profile_url"
                  class="mr-2 mb-1 live_profile_img"
                />
                {{ current_speaker.speaker_name }}
              </div>
              <h6
                style="max-height: calc(100vh - 59vh); overflow-y: auto"
                class="text-primary text-left mt-3"
                v-if="current_speaker != null"
              >
                {{ current_speaker.bio }}
              </h6>
            </div>
          </div>

          <!-- <div class="col-md-2"></div> -->
          <div class="col-md-9">
            <div class="live-video" style="padding: 0 0">
              <div
                class="frame-wrapper c-frame-wrapper"
                style="width: 100%; position: relative"
              >
                <div
                  class="frame-wrapper frame-outline"
                >
                  <img
                    :src="require('@/assets/img/frame.png')"
                    style="
                      position: absolute;
                      left: 0;
                      width: 100%;
                      height: 100%;
                    "
                  />
                  <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.restream.io/?token=6f676fb943a04377b82c7081b74b380e&vwrs=1" allow="autoplay" allowfullscreen frameborder="0" style="position:absolute;top:0;left:0;width:100%;height:100%;"></iframe></div>
                </div>
              </div>
              <!-- <div class="frame-wrapper" style="width: 100%">
                <img
                  :src="require('@/assets/img/frame.png')"
                  style="
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 59vh;
                    z-index: 1;
                  "
                />
              </div> -->

              <div class="text-center css-layout" v-if="show_css_layout">
                <div
                  class="d-flex align-items-center justify-content-center"
                  style="height: 100%"
                >
                  <h2 style="color: yellow">
                    Stand by<br />for our<br />next speaker.<br />Please click
                    Join Service<br />
                    once you see an image
                  </h2>
                </div>
              </div>
              <transition name="pop" appear>
                <div
                  class="modal fade show custom-modal"
                  v-if="show_count_modal"
                  id="Countdown"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="Countdown"
                  aria-hidden="true"
                  style="z-index: 9000"
                >
                  <div
                    class="modal-dialog modal-dialog-centered"
                    style="width: 300px !important"
                    role="document"
                  >
                    <div class="modal-content">
                      <div class="modal-body">
                        <div class="row">
                          <div class="col-lg-12 text-center">
                            <h5 class="text-custom my-4">
                              Stand by<br />for our<br />next speaker.
                            </h5>
                            <div class="countdown">
                              {{ count_number }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <button
              id="join_service"
              class="btn-play"
              v-show="runningVideo"
              onclick="document.getElementById('remote_video').play();document.getElementById('join_service').remove()"
            >
              Join Service
            </button>
            <div class="position-absolute" style="right:-9px;bottom:-24px">
              <v-btn
              rounded
              class="play-btn"
              text
              @click="changeScreenView()"
              >
                <img
                style="height:50px;background-color: black;"
                :src="minimize"
                />
              </v-btn>
            </div>
          </div>
          <!-- <div class="col-md-3"></div> -->
        </div>
      </div>
    </section>
    <footer class="pl-5">
      <div class="d-flex">
        <div class="col-md-12">
          <div class="d-flex align-items-center" style="height: 130px">
            <div>
              <p class="text-primary text-left pr-2" style="font-size: 24px">
                LIVE MESSAGES:
              </p>
            </div>
            <div style="width: 325px" class="mx-2">
              <div
                class="my-auto d-flex align-items-center"
                v-if="latest_profile_url != '' && latest_profile_name != ''"
              >
                <div class="profile-pic border-0 mb-2">
                  <img
                    :src="latest_profile_url"
                    class="mr-3"
                    style="width: 80px; height: 80px"
                  />
                </div>
                <div>
                  <div
                    class="text-primary text-left mb-0"
                    style="font-size: 22px; font-weight: 600"
                  >
                    {{ latest_profile_name }}
                  </div>
                  <div
                    class="text-primary text-left mb-0"
                    style="overflow-y: auto; max-height: 100px"
                  >
                    {{ latest_message }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--        <div class="col-md-3">-->
        <!--          <div class="img-box mt-0 pointer" @click="goBack">-->
        <!--            <img :src="require('@/assets/img/return_program.png')" class="img-fluid float-left mr-2">-->
        <!--            <label class="text-primary">Return to<br>-->
        <!--              program-->
        <!--            </label>-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </footer>
    <!-- Modal -->

    <!-- <transition name="pop" appear>
      <div
        class="modal fade show program-modal"
        v-if="show_modal"
        id="myModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="myModal"
        aria-hidden="true"
        @click="closeModal"
      >
        <div
          class="modal-dialog modal-dialog-centered"
          role="document"
          v-show="!runningVideo && runningSourceVideo"
        >
          <div class="modal-content">
            <div class="modal-body">
              <p>Please click on play button when you see the video</p>
              <button
                type="button"
                class="okay"
                data-dismiss="modal"
                aria-label="Close"
                @click="closeModal"
              >
                <span aria-hidden="true">Okay</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition> -->

    <!--    <transition name="pop" appear>-->
    <!--      <div class="custom-modal note-modal" v-if="false" id="EulogyNotes" tabindex="-1" role="dialog" aria-labelledby="Eulogy Notes" aria-hidden="true" v-draggable="draggableValue">-->
    <!--        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">-->
    <!--          <div class="modal-content">-->
    <!--            <div class="modal-body">-->
    <!--                <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
    <!--                  <span aria-hidden="true">&times;</span>-->
    <!--                </button>-->
    <!--              <div class="row">-->
    <!--                <div class="col-lg-12">-->
    <!--                  <h5 class="text-custom text-left"><img :src="require('@/assets/img/move_icon.png')" :ref="handleId">Notes</h5>-->
    <!--                </div>-->
    <!--                <div class="col-lg-12 text-custom mt-4 scroll-notes" style="max-height: 200px">-->
    <!--                  {{current_user.notes}}-->
    <!--                </div>-->
    <!--              </div>-->
    <!--            </div>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </transition>-->
  </div>
</template>
<script>
import { getApiManager } from "../../api";
import {
  admin_key,
  apiBaseUrl,
  company_prefix,
  liveBaseUrl,
} from "../../constants/config";
import Room from "janus-room";
import { janusUrl, iceServer } from "../../constants/config";
import { Draggable } from "draggable-vue-directive";
import adapter from "webrtc-adapter";
import maximize from "../../../src/assets/img/fullScreen.png";
import minimize from "../../../src/assets/img/normalScreen.png";
window["adapter"] = adapter;

export default {
  directives: {
    Draggable,
  },
  data() {
    return {
      handleId: "handle-id",
      draggableValue: {
        handle: undefined,
      },
      video_source: "",
      runningVideo: false,
      showbroad: true,
      runningSourceVideo: false,
      event_id: 0,
      speakers: [],
      visitors: [],
      event: null,
      visitor_id: 0,
      connection: null,
      room: null,
      current_seq_name: "",
      current_user: null,
      current_speaker: null,
      notes: "",
      condolences: [],
      show_css_layout: false,
      params: { autoplay: 0 },
      youtube_url: "",
      show_modal: true,
      latest_message: "",
      latest_visitor_id: "",
      show_count_modal: false,
      count_number: 25,
      latest_profile_url: "",
      latest_profile_name: "",
      nextspeaker: false,
      viewerRoomUrl: "",
      isFullScreen:true,
      maximize:maximize,
      minimize:minimize,
      isCloseInfo : false,
    };
  },
  mounted() {
    this.draggableValue.handle = this.$refs[this.handleId];

    this.event_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).event_id
      : 0;
    this.visitor_id = JSON.parse(localStorage.getItem("loginInfo"))
      ? JSON.parse(localStorage.getItem("loginInfo")).id
      : 0;
    console.log("OOOOOOOOOOOOOOOO", this.event_id);
    this.getDetail();
    this.getEventDetail();
    this.connectSocket();
    this.getCondolences();
  },
  methods: {
    changeScreenView(){
      this.isFullScreen=this.isFullScreen==true?false:true;
      if(this.isFullScreen==false){
        this.getDetail();
      }
    },
    closeModal() {
      this.show_modal = false;
    },
    getEventDetail() {
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/event/getEventDetail`, {
          event_id: this.event_id,
        })
        .then((result) => {
          console.log("(((((((((((((((((((", result);

          if (result.data.status == true) {
            // let object=document.getElementById('image')
            // if (result.data.data[0].background_url!=null && result.data.data[0].background_url!='') {
            //   object.style.backgroundImage="url('" + `${apiBaseUrl}` + "/" + result.data.data[0].background_url + "')";
            // }
            this.viewerRoomUrl =
              result.data.data[0].viewerRoomUrl +
              "&chat=off&displayName=test&minimal=on";
            console.log("77777777777777777777777777777", this.viewerRoomUrl);
          }
        });
    },
    live() {
      getApiManager()
        .post(`${apiBaseUrl}/api/vonage/getToken`, {
          decease_name: this.event.decease_name,
        })
        .then((result) => {
          this.countDownTimer();
          setTimeout(() => {
            console.log("%%%%%%%%%%%", result);
            this.youtube_url = result.data.channel_id + "&autoplay=1";
            this.show_count_modal = false;
            console.log("^^^^^^^^^^^", this.youtube_url);
          }, 25000);
        });
    },
    connectSocket() {
      var ref = this;

      this.connection = new WebSocket(liveBaseUrl);
      this.connection.onmessage = function (event) {
        //this.isLoading = false;
        let data = JSON.parse(event.data);
        console.log(data);
        if (data.type == "change_live") {
          console.log("12121212");
          this.nextspeaker = true;
          // setTimeout(() => {
          ref.receiveLiveUser(data.order, data.speaker);
          // }, 10000);
        } else if (data.type == "user_info") {
          ref.connection.send(
            JSON.stringify({
              type: "my_info",
              event_id: ref.event_id,
            })
          );
        } else if (data.type == "end_live") {
          //route after finishes lives
          let randomnumber = Math.floor(Math.random() * 10000) + 1;
          ref.$router.push("/visitor/parting/"+data.event_id+"?x=" + randomnumber);
        } else if (data.type == "change_speaker_bio") {
          ref.current_speaker = data.speaker;
          ref.isCloseInfo = data.isClose;
        } else if (data.type == "get_message") {
          ref.getCondolences();
        }else if(data.type == "update_visitors"){
          ref.getDetail()
        }
      };

      this.connection.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second."
        );
        setTimeout(() => {
          ref.connectSocket();
        }, 1000);
      };
    },
    showCss() {
      this.show_css_layout = true;
      this.show_count_modal = true;
      console.log("2222222222");
      document.getElementById("join_service").style.backgroundColor = "grey";
      document.getElementById("join_service").disabled = true;
      // let target = document.getElementById("remote_video");
      // target.muted = true;
      let ref = this;
      setTimeout(function () {
        ref.show_css_layout = false;
        ref.show_count_modal = false;
        ref.show_modal = true;
        document.getElementById("join_service").disabled = false;
        document.getElementById("join_service").style.backgroundColor =
          "#fcd351";
        // target.muted = false;
        ref.$forceUpdate();
      }, 25000);
    },
    showCssforswitchspeaker() {
      this.show_css_layout = true;
      this.show_count_modal = true;
      this.count_number = 15;
      this.countDownTimer();
      document.getElementById("join_service").style.backgroundColor = "grey";
      document.getElementById("join_service").disabled = true;
      // let target = document.getElementById("remote_video");
      // target.muted = true;
      let ref = this;
      setTimeout(function () {
        ref.show_css_layout = false;
        ref.show_count_modal = false;
        ref.show_modal = true;
        document.getElementById("join_service").disabled = false;
        document.getElementById("join_service").style.backgroundColor =
          "#fcd351";
        // target.muted = false;
        ref.$forceUpdate();
      }, 15000);
    },
    showCssforswitchspeakerwithvideo() {
      this.show_css_layout = true;
      this.show_count_modal = true;
      this.count_number = 15;
      this.countDownTimer();
      document.getElementById("join_service").style.backgroundColor = "grey";
      document.getElementById("join_service").disabled = true;
      // let target = document.getElementById("remote_video");
      // target.muted = true;
      let ref = this;
      setTimeout(function () {
        ref.show_css_layout = false;
        ref.show_count_modal = false;
        ref.show_modal = true;
        document.getElementById("join_service").disabled = false;
        document.getElementById("join_service").style.backgroundColor =
          "#fcd351";
        // target.muted = false;
        ref.$forceUpdate();
      }, 15000);
    },
    countDownTimer() {
      console.log("55555555555555", this.count_number);
      if (this.count_number > 0) {
        setTimeout(() => {
          this.count_number -= 1;
          this.countDownTimer();
        }, 1000);
      } else {
        this.show_count_modal = false;
      }
    },
    getCondolences() {
      let ref = this;
      let i = 0;
      getApiManager()
        .post(`${apiBaseUrl}/api/admin/condolence/all`, {
          event_id: this.event_id,
        })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let data = res.data;
            if (data.length > 0) {
              let u_id = data[0].content_type_id
                ? data[0].content_type_id
                : data[0].visitor_id;
              let u_type = data[0].content_type_id ? "speaker" : "visitor";
              let condolence = {
                profile_url: data[0].content_type_id
                  ? apiBaseUrl + "/" + data[0].profile_url
                  : apiBaseUrl + "/" + data[0].visitor_profile_url,
                profile_name: data[0].content_type_id
                  ? data[0].speaker_name
                  : data[0].full_name,
                messages: [],
              };
              condolence.messages.push(data[0].message);
              this.latest_message = data[i].message;
              this.latest_visitor_id = data[i].content_type_id
                ? data[i].content_type_id
                : data[i].visitor_id;
              this.latest_profile_url = data[i].content_type_id
                ? apiBaseUrl + "/" + data[i].profile_url
                : apiBaseUrl + "/" + data[i].visitor_profile_url;
              this.latest_profile_name = data[i].content_type_id
                ? data[i].speaker_name
                : data[i].full_name;

              setInterval(() => {
                i++;
                if (i == data.length) {
                  i = 0;
                }
                this.latest_message = data[i].message;
                this.latest_visitor_id = data[i].content_type_id
                  ? data[i].content_type_id
                  : data[i].visitor_id;
                this.latest_profile_url = data[i].content_type_id
                  ? apiBaseUrl + "/" + data[i].profile_url
                  : apiBaseUrl + "/" + data[i].visitor_profile_url;
                this.latest_profile_name = data[i].content_type_id
                  ? data[i].speaker_name
                  : data[i].full_name;
              }, 30000);

              // for (let i = 1; i < data.length; i++) {
              //   let loop_id = data[i].content_type_id
              //     ? data[i].content_type_id
              //     : data[i].visitor_id;
              //   let loop_type = data[i].content_type_id ? "speaker" : "visitor";
              //   if (u_type == loop_type && u_id == loop_id) {
              //     condolence.messages.push(data[i].message);
              //   } else {
              //     ref.condolences.push(condolence);
              //     condolence = {
              //       profile_url: data[i].content_type_id
              //         ? apiBaseUrl + "/" + data[i].profile_url
              //         : apiBaseUrl + "/" + data[i].visitor_profile_url,
              //       profile_name: data[i].content_type_id
              //         ? data[i].speaker_name
              //         : data[i].full_name,
              //       messages: [],
              //     };
              //     condolence.messages.push(data[i].message);
              //     u_id = data[i].content_type_id
              //       ? data[i].content_type_id
              //       : data[i].visitor_id;
              //     u_type = data[i].content_type_id ? "speaker" : "visitor";
              //   }
              // }
              // ref.condolences.push(condolence);
              // console.log(ref.condolences);
            }
          }
        })
        .catch((error) => {});
    },
    getDetail() {
      var ref = this;
      getApiManager()
        .post(`${apiBaseUrl}/api/visitor/all`, { event_id: this.event_id })
        .then((response) => {
          let res = response.data;
          if (res.status === true) {
            let detail = res.detail;
            ref.speakers = detail.speakers;
            for (var i = 0; i < ref.speakers.length; i++) {
              ref.speakers[i].profile_url =
                apiBaseUrl + "/" + ref.speakers[i].profile_url;
            }
            ref.visitors = detail.visitors;
            for (i = 0; i < ref.visitors.length; i++) {
              ref.visitors[i].profile_url =
                apiBaseUrl + "/" + ref.visitors[i].profile_url;
            }
            ref.event = detail.event;
            ref.setCurrentOrder(ref.event.cur_order);
            ref.setCurrentUser(ref.event.cur_speaker);
            // ref.startLive();
            ref.showCss();
            this.live();
          }
        })
        .catch((error) => {});
    },
    setCurrentUser(speaker) {
      this.event.cur_speaker = speaker;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].content_type_id == speaker) {
          this.current_user = this.speakers[i];
        }
      }
      if (this.current_user.speaker_type == 0) {
        this.setVideoUrl(this.current_user.video_url);
      } else {
        this.runningSourceVideo = false;
        this.video_source = "";
        this.$forceUpdate();
      }
    },
    setCurrentOrder(order) {
      this.event.cur_order = order;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].order == order) {
          this.current_seq_name = this.speakers[i].name;
        }
      }
    },
    setVideoUrl(url) {
      this.runningVideo = false;
      this.showbroad = false;
      this.runningSourceVideo = true;

      if (url.includes("watch") == true) {
        if (
          url.split("=")[1] != null &&
          url.split("=")[1] != undefined &&
          url.split("=")[1] != ""
        ) {
          setTimeout(() => {
            this.video_source =
              "https://www.youtube.com/embed/" +
              url.split("=")[1] +
              "?autoplay=1";
          }, 25000);
        } else {
          setTimeout(() => {
            this.video_source = url;
          }, 25000);
        }
      } else {
        setTimeout(() => {
          this.video_source = url;
        }, 25000);
      }
      this.params = { autoplay: 1 };
      this.$forceUpdate();
    },
    receiveLiveUser(order, speaker) {
      console.log("99999999999999999");
      this.nextspeaker = false;
      for (var i = 0; i < this.speakers.length; i++) {
        if (this.speakers[i].content_type_id == speaker) {
          this.current_user = this.speakers[i];
          if (this.speakers[i].speaker_type == 0) {
            this.showCssforswitchspeakerwithvideo();
          } else {
            this.showCssforswitchspeaker();
          }
        }
      }

      this.event.cur_order = order;
      this.event.cur_speaker = speaker;
      this.setCurrentOrder(order);
      this.setCurrentUser(speaker);
    },
    onLocalJoin() {},
    onRemoteJoin(index, remoteUsername, feedId) {
      console.log("Receive Remote video");
      this.runningVideo = true;
      // let target = document.getElementById("remote_video");
      // this.room.attachStream(target, index);
    },
    onRemoteUnjoin(index) {
      this.runningVideo = false;
    },
    onMessage(data) {
      console.log(data);
    },
    onError(err) {
      console.log(err);
    },
    registerUserName() {
      this.room.register({
        username: "visitor_" + this.visitor_id,
        room: parseInt(company_prefix) + parseInt(this.event_id),
      });
    },
    startLive() {
      var publishOwnFeed = false;
      var videocodec = "h264";
      var h264_profile = "42e01f";

      var options = {
        server: janusUrl, // required
        iceservers: iceServer,
        // Event handlers
        onLocalJoin: this.onLocalJoin,
        // onRemoteJoin: this.onRemoteJoin,
        onRemoteUnjoin: this.onRemoteUnjoin,
        onMessage: this.onMessage,
        onError: this.onError,
        room: parseInt(company_prefix) + parseInt(this.event_id),
        // publishOwnFeed: publishOwnFeed,
        videocodec: videocodec,
        h264_profile: h264_profile,
      };
      this.room = new Room(options);
      var ref = this;
      this.room
        .init()
        .then(function () {
          ref.createRoom();
        })
        .catch(function (err) {
          console.log(err);
        });
    },
    createRoom() {
      var ref = this;
      this.room
        .createRoom({
          room: parseInt(company_prefix) + this.event_id,
          publishers: 100,
          admin_key: admin_key,
          videocodec: "h264",
          h264_profile: "42e01f",
        })
        .then(() => {
          setTimeout(function () {
            ref.registerUserName();
          }, 1000);
        })
        .catch((err) => {
          alert(err);
        });
    },
    goBack() {
      let randomnumber = Math.floor(Math.random() * 10000) + 1;
      this.$router.push("/visitor/dashboard/"+this.event_id+"?x=" + randomnumber);
    },
  },
  components: {},
  computed: {
    counter() {
      return this.$store.getters.token;
    },
  },
  watch: {},
};
</script>
<style>
 .frame-outline {
      width: 100%;
      padding: 47px;
    }
@media (min-width:350px) {
  .frame-outline {
    width: 100%;
    padding: 20px;
  }
    
}
@media (min-width:576px) {
  .frame-outline {
      width: 100%;
      padding: 20px;
    }
    
}

@media (min-width:768px) {
    .frame-outline {
      width: 100%;
      padding: 31px;
    }
}
/* .frame_wrapper .RootLayout-3Qtw .content-2tzR {
  height: 270px !important;
  background-color: aquamarine !important;
} */
</style>
